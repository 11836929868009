import React from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

import './videos.css';

import device from '../device';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ImageGalleryContainer = styled.div`
    margin-bottom: 1.5rem;

    @media ${device.laptop} {
        width: 60%;
        margin: 0 auto;
    }
`;

export default class VideosGallaryPage extends React.Component {
    constructor() {
        super();
        this.state = {
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showVideo: {},
        };

        this.items = [
            {
                original:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F%2060863908_815175202191317_6403942290631841501_n.png?alt=media&token=6c73b04f-7e2d-401f-830c-c47d31a0708a',
                thumbnail:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F%2060863908_815175202191317_6403942290631841501_n.png?alt=media&token=6c73b04f-7e2d-401f-830c-c47d31a0708a',
                embedUrl:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F60863908_815175202191317_6403942290631841501_n.mp4?alt=media&token=c9a7fe08-e7ad-4854-98a2-253f531a4717',
                description: 'חם מהתנור! חוג טרמפולינות בשילוב TRX',
                renderItem: this._renderVideo.bind(this),
            },
            {
                original:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F58427551_341580129826086_6972959228214716757_n.jpg?alt=media&token=d75f0243-61ea-4cb7-a2d7-e3c9835575f8',
                thumbnail:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F58427551_341580129826086_6972959228214716757_n.jpg?alt=media&token=d75f0243-61ea-4cb7-a2d7-e3c9835575f8',
                embedUrl:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F58427551_341580129826086_6972959228214716757_n.mp4?alt=media&token=2d5b2b9a-080e-40c4-bb04-82ce6aee19b1',
                description: 'עוד טעימה מהאימון הלוהט',
                renderItem: this._renderVideo.bind(this),
            },
            {
                original:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F%2042046275_511906285903693_5568084130400305152_n.jpg?alt=media&token=3c64a47e-eb31-4a7f-9300-0d9be644e891',
                thumbnail:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F%2042046275_511906285903693_5568084130400305152_n.jpg?alt=media&token=3c64a47e-eb31-4a7f-9300-0d9be644e891',
                embedUrl:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F42046275_511906285903693_5568084130400305152_n.mp4?alt=media&token=8ae8f853-b250-4193-b6f8-ca35f2f4c830',
                description: 'אירובי דאנס',
                renderItem: this._renderVideo.bind(this),
            },
            {
                original:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F45621059_332523293970569_942516305225842688_n.jpg?alt=media&token=c5cf4e65-1e9b-4f46-b451-123aa3ecc710',
                thumbnail:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F45621059_332523293970569_942516305225842688_n.jpg?alt=media&token=c5cf4e65-1e9b-4f46-b451-123aa3ecc710',
                embedUrl:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F45621059_332523293970569_942516305225842688_n.mp4?alt=media&token=976ad4ad-b1e8-4260-8900-1b3312329bff',
                description: 'אימון HIIT',
                renderItem: this._renderVideo.bind(this),
            },
            {
                original:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F49096284_347830585765112_5167265597515366400_n.jpg?alt=media&token=2e13ec9c-9f69-4c36-a3f7-b18f89b4c27d',
                thumbnail:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F49096284_347830585765112_5167265597515366400_n.jpg?alt=media&token=2e13ec9c-9f69-4c36-a3f7-b18f89b4c27d',
                embedUrl:
                    'https://firebasestorage.googleapis.com/v0/b/studio-ms-fitnes-1508130649213.appspot.com/o/videos%2F49096284_347830585765112_5167265597515366400_n.mp4?alt=media&token=1aba0e70-3b6b-4f0b-89ea-58244b44b257',
                description: 'עיצוב דינמי',
                renderItem: this._renderVideo.bind(this),
            },
        ];
    }

    render() {
        return (
            <Layout {...this.props}>
                <SEO
                    title="סרטונים | פילאטיס בראשון לציון | עיצוב בראשון לציון | זומבה בראשון לציון"
                    keywords={[
                        `פילאטיס בראשון לציון`,
                        `זומבה בראשון לציון`,
                        `עיצוב וחיטוב בראשון לציון`,
                        `סטודיו לנשים בראשון לציון`,
                    ]}
                />
                <ImageGalleryContainer>
                    <ImageGallery
                        items={this.items}
                        isRTL
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets
                    />
                </ImageGalleryContainer>
            </Layout>
        );
    }

    _toggleShowVideo(url) {
        let showVideo = Object.assign({}, this.state.showVideo);
        showVideo[url] = !!!showVideo[url];
        this.setState({
            showVideo,
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({ showGalleryPlayButton: false });
            }

            if (this.state.showFullscreenButton) {
                this.setState({ showGalleryFullscreenButton: false });
            }
        }
    }

    _renderVideo(item) {
        return (
            <div className="image-gallery-image">
                {this.state.showVideo[item.embedUrl] ? (
                    <div className="video-wrapper">
                        <a className="close-video" onClick={this._toggleShowVideo.bind(this, item.embedUrl)} />
                        <iframe
                            title="פילאטיס בראשון לציון | עיצוב בראשון לציון | זומבה בראשון לציון"
                            width="560"
                            height="315"
                            src={item.embedUrl}
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                ) : (
                    <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                        <div className="play-button" />
                        <img src={item.original} alt="" />
                        {item.description && (
                            <span className="image-gallery-description" style={{ right: '0', left: 'initial' }}>
                                {item.description}
                            </span>
                        )}
                    </a>
                )}
            </div>
        );
    }
}
